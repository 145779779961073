<!-- =========================================================================================
    File Name: Invoice.vue
    Description: Invoice Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="invoice-page">

    <div class="flex flex-wrap items-center justify-between">
      <div class="flex items-center">
        <vs-button class="mb-base mr-3" icon-pack="feather" icon="icon icon-file" @click="printInvoice">طباعة
        </vs-button>
      </div>
    </div>

    <vx-card id="invoice-container">

      <!-- INVOICE METADATA -->
      <div class="vx-row leading-loose p-base">
        <div class="vx-col w-full md:w-1/2 mt-base">
          <img src="@/assets/images/logo/almithalylogo.svg" width="150" height="62.45" alt="vuexy-logo">
        </div>
        <div class="vx-col w-full md:w-1/2 text-right">
          <h1>فاتورة</h1>
          <div class="invoice__invoice-detail mt-6">
            <h6>رقم الفاتورة.</h6>
            <p>{{ invoiceDetails.invoiceNo }}</p>

            <h6 class="mt-4">تاريخ الطلب</h6>
            <p>{{ invoiceDetails.invoiceDate }}</p>
          </div>
        </div>
        <div class="flex w-full justify-between mx-5">
          <div class="vx-col w-full md:w-1/2 mt-12">
            <h5>تم التسليم إلي السيد/ة</h5>
            <div class="invoice__recipient-info my-4">
              <p>{{ recipientDetails.username }}</p>
              <p>{{ recipientDetails.address }}</p>
            </div>
            <div class="invoice__recipient-contact ">
              <p class="flex items-center">
                <feather-icon icon="PhoneIcon" svgClasses="h-4 w-4"></feather-icon>
                <span class="ml-2">{{ recipientDetails.phone }}</span>
              </p>
            </div>
          </div>
          <div class="vx-col w-full md:w-1/2 mt-base text-right mt-12">
            <h5>ALBINA ALMITHALY CO. HOME APPLIANCES</h5>
            <div class="invoice__company-info my-4">
              <p>حي الأندلس</p>
              <p>طرابلس</p>
            </div>
            <div class="invoice__company-contact">
              <p class="flex items-center justify-end">
                <feather-icon icon="MailIcon" svgClasses="h-4 w-4"></feather-icon>
                <span class="ml-2">sales@almithaly.ly</span>
              </p>
              <p class="flex items-center justify-end">
                <feather-icon icon="PhoneIcon" svgClasses="h-4 w-4"></feather-icon>
                <span class="ml-2">0910092323</span>
              </p>
            </div>

          </div>
        </div>
      </div>

      <!-- INVOICE CONTENT -->
      <div class="p-base">
        <!-- INVOICE Items TABLE -->
        <vs-table hoverFlat :data="invoiceData.items">
          <!-- HEADER -->
          <template slot="thead">
            <vs-th> id</vs-th>
            <vs-th> sku</vs-th>
            <vs-th>إسم المنتج</vs-th>
            <vs-th>صورة المنتج</vs-th>
            <vs-th>السعر</vs-th>
            <vs-th>سعر التخفيض</vs-th>
            <vs-th>الكمية</vs-th>
            <vs-th>الإجمالي</vs-th>
          </template>

          <!-- DATA -->

          <template slot-scope="{data}">
            <vs-tr v-for="(tr, index) in data" :key="index">
              <vs-td class="span">{{ tr.product.id }}</vs-td>
              <vs-td class="span">{{ tr.product.sku }}</vs-td>
              <vs-td class="span" v-if="tr.product">{{ tr.product.name }}</vs-td>
              <vs-td class="span" v-else>{{ tr.package.name }}</vs-td>

              <vs-td v-if="tr.product">
                <img style="max-width:30px" :src="tr.product.media[0].full_path" alt="" srcset="">
              </vs-td>
              <vs-td v-else>-</vs-td>


              <vs-td class="span" v-if="tr.product">{{ tr.price }} د.ل</vs-td>
              <vs-td class="span" v-else>{{ tr.package.total }} د.ل</vs-td>
              <vs-td class="span">{{ tr.new_price }} د.ل</vs-td>
              <vs-td class="span">{{ tr.quantity }} </vs-td>

              <vs-td class="span" v-if="tr.new_price != 0">{{ tr.new_price * tr.quantity }}
                د.ل</vs-td>
              <vs-td class="span" v-else-if="tr.product">{{ tr.price * tr.quantity }} د.ل</vs-td>
              <vs-td class="span" v-else>{{ tr.package.total }} د.ل</vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <!-- INVOICE SUMMARY TABLE -->
        <vs-table hoverFlat class="w-1/2 ml-auto mt-4" data="invoiceData">
          <vs-tr>
            <vs-th>المبلغ الإجمالي</vs-th>
            <vs-td>{{ invoiceInvoice }} د.ل</vs-td>
          </vs-tr>

          <vs-tr>
            <vs-th>التخفيض</vs-th>
            <vs-td>{{ invoiceData.discountedAmount }} د.ل</vs-td>
          </vs-tr>



          <vs-tr>
            <vs-th>سعر التوصيل</vs-th>
            <td>{{ invoiceData.delivery_price }} د.ل</td>
          </vs-tr>

          <vs-tr>
            <vs-th>الكلي</vs-th>
            <td>{{ invoiceNetInvoice }} د.ل</td>
          </vs-tr>

          <vs-tr>
            <vs-th>وسيلة الدفع</vs-th>
            <td class="flex">{{ invoiceData.paymentMethod }}
              <img src="@/assets/images/cash.png" style="height: 25px; margin:0 8px">
            </td>

          </vs-tr>
        </vs-table>
      </div>
    </vx-card>
  </div>
</template>

<script>
import moduleOrder from "@/store/orders/moduleOrder.js";
export default {
  data() {
    return {
      recipientDetails: {},
      invoiceDetails: {
        invoiceNo: '',
        invoiceDate: 'this.tr.createdAt',
      },
      order_id: "",
      invoiceData: {
        items: [],
        total: '',
        delivery_price: "",
        discountedAmount: 0,
        paymentMethod: ""
      }
    }
  },
  computed: {
    invoiceNetInvoice() {
      let total = 0;
      if (this.invoiceData.items.length) {
        this.invoiceData.items.map(item => {
          if (item.new_price > 0) {

            total += (item.new_price * item.quantity)
          } else {

            total += (item.price * item.quantity)
          }
        })
      }
      return total - this.invoiceData.discountedAmount + this.invoiceData.delivery_price;
    },
    invoiceInvoice() {
      let total = 0;
      if (this.invoiceData.items.length) {
        this.invoiceData.items.map(item => {
          if (item.new_price > 0) {
            total += (item.new_price * item.quantity);
          } else {
            total += (item.price * item.quantity);
          }
        })
      }
      return total
    }
  },
  methods: {
    printInvoice() {
      window.print()
    },
    getOrderInvoice() {
      this.$store.dispatch("order/getOrderInvoice", this.order_id)
        .then((response) => {
          this.recipientDetails = response.data.user;
          this.invoiceDetails.invoiceNo = response.data.id;
          this.invoiceDetails.invoiceDate = response.data.delivery_date;
          this.invoiceData.items = response.data.items;
          this.invoiceData.delivery_price = response.data.delivery_price;
          this.invoiceData.total = response.data.total;
          this.invoiceData.discountedAmount = response.data.discount;
          this.invoiceData.paymentMethod = response.data.payment_method.name;
        })
    }
  },
  components: {},
  created() {
    if (!moduleOrder.isRegistered) {
      this.$store.registerModule("order", moduleOrder);
      moduleOrder.isRegistered = true;
    }
  },
  mounted() {
    this.$emit("setAppClasses", "invoice-page");
    this.order_id = localStorage.getItem("order_id");
    if (this.order_id) {
      this.getOrderInvoice()
      document.title = ' الفاتورة رقم ' + this.order_id;
    }
  }
}

</script>

<style lang="scss">
.span {
  font-size: 14px !important;
}

@page {
  size: auto;
  /* auto is the initial value */
  margin: 0;
  /* this affects the margin in the printer settings */
}

@media print {
  .invoice-page {
    * {
      visibility: hidden;
    }

    #content-area {

      margin: 0 !important;
    }

    #invoice-container,
    #invoice-container * {
      visibility: visible;
    }

    #invoice-container {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
  }
}
</style>
